// src/components/SkillsList/SkillsList.js
import React, { useEffect, useState, useContext, useCallback } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { db } from '../../firebase/firebase';
import {
  collection,
  getDocs,
  query,
  where,
  doc,
  getDoc
} from 'firebase/firestore';
import { UserContext } from '../../contexts/UserContext';
import './SkillsList.css';
import { toast } from 'react-toastify';

const SkillsList = () => {
  const {
    gradeLevel: paramGradeLevel,
    levelId: paramLevelId,
    focusArea: paramFocusArea
  } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isCustomPlan = queryParams.get('customPlan') === 'true';

  const { userData } = useContext(UserContext);

  const [skills, setSkills] = useState([]);
  const [completedSkills, setCompletedSkills] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchSkills = useCallback(async () => {
    if (!userData) {
      setError('User data not available (not logged in).');
      setLoading(false);
      return;
    }

    if (!paramFocusArea || !paramGradeLevel) {
      setError('Insufficient parameters to load skills.');
      setLoading(false);
      return;
    }

    try {
      if (isCustomPlan) {
        // CUSTOM PLAN: Fetch the focus area doc and filter its skills array by the teacher's grade.
        const faDocRef = doc(db, 'focusAreas', paramFocusArea);
        const faDoc = await getDoc(faDocRef);
        if (!faDoc.exists()) {
          setError('Focus area not found.');
          setLoading(false);
          return;
        }
        const allSkillIds = faDoc.data().skills || [];
        // Filter skill IDs that include the teacher's grade (e.g., "grade_1" or for "grade_k", look for "_k_")
        let filteredSkillIds;
        if (paramGradeLevel === 'grade_k') {
          // For kindergarten, look for skill IDs with "_k_" pattern
          filteredSkillIds = allSkillIds.filter(id => id.includes('_k_'));
        } else {
          filteredSkillIds = allSkillIds.filter(id => id.includes(paramGradeLevel));
        }
        if (filteredSkillIds.length === 0) {
          setError('No skills found for this focus area for your grade.');
          setLoading(false);
          return;
        }
        // Fetch each skill doc in parallel.
        const skillDocPromises = filteredSkillIds.map(id => getDoc(doc(db, 'skills', id)));
        const skillDocs = await Promise.all(skillDocPromises);
        const fetchedSkills = skillDocs.map(docSnap => ({
          id: docSnap.id,
          ...docSnap.data()
        }));
        setSkills(fetchedSkills);
      } else {
        // DEFAULT PLAN: Query the skills collection using grade, level, and focus area.
        const skillsRef = collection(db, 'skills');
        let qSkills;
        
        if (paramGradeLevel === 'grade_k') {
          // For kindergarten, we need to use a different approach
          qSkills = query(
            skillsRef,
            where('focusAreaId', '==', paramFocusArea)
          );
        } else {
          qSkills = query(
            skillsRef,
            where('gradeId', '==', paramGradeLevel),
            where('levelId', '==', paramLevelId),
            where('focusAreaId', '==', paramFocusArea)
          );
        }
        const snapshot = await getDocs(qSkills);
        if (snapshot.empty) {
          setError('No skills found for this level/focus area.');
          setLoading(false);
          return;
        }
        let fetchedSkills = snapshot.docs.map(docSnap => ({
          id: docSnap.id,
          ...docSnap.data()
        }));
        
        // For kindergarten, manually filter skills to match _k_ pattern
        if (paramGradeLevel === 'grade_k') {
          fetchedSkills = fetchedSkills.filter(skill => 
            // Match patterns like "skill_buildingConfidence_k_9_7"
            skill.id.includes('_k_') || 
            // Also include docs explicitly marked with gradeId
            skill.gradeId === 'grade_k'
          );
        }
        
        setSkills(fetchedSkills);
      }

      // Determine completed skills for this focus area.
      const classId = userData.currentClassId || '';
      const gradeData = userData.completedSkillsByGrade || {};
      const gradeObj = gradeData[paramGradeLevel] || {};
      const completedForFocus = gradeObj[classId]?.[paramFocusArea] || [];
      setCompletedSkills(Array.isArray(completedForFocus) ? completedForFocus : []);
      setLoading(false);
    } catch (err) {
      console.error('Error fetching skills:', err);
      setError('Failed to load skills. Please try again later.');
      setLoading(false);
    }
  }, [userData, paramFocusArea, paramGradeLevel, paramLevelId, isCustomPlan]);

  useEffect(() => {
    fetchSkills();
  }, [fetchSkills]);

  const handleSkillClick = (skill) => {
    // Navigate to skill detail page
    navigate(
      `/grades/${encodeURIComponent(paramGradeLevel)}/levels/${encodeURIComponent(
        paramLevelId
      )}/skills/${encodeURIComponent(paramFocusArea)}/${encodeURIComponent(skill.id)}`
    );
  };

  if (loading) return <p>Loading skills...</p>;
  if (error) return <p className="error-message">{error}</p>;

  if (skills.length === 0) {
    return (
      <div className="no-skills-container">
        <h2>Skills for {paramFocusArea}</h2>
        <p>No skills found for this focus area.</p>
        <button onClick={() => navigate(-1)} className="back-button">
          Go Back
        </button>
      </div>
    );
  }

  return (
    <div className="skills-list-container">
      <h2>Skills for {paramFocusArea}</h2>
      <ul className="skills-list">
        {skills.map((skill) => {
          const isCompleted = completedSkills.includes(skill.id);
          return (
            <li
              key={skill.id}
              className={`skill-item ${isCompleted ? 'completed' : 'incomplete'}`}
            >
              <div className="skill-info">
                <span className="skill-name">{skill.name || 'Unnamed Skill'}</span>
                <span className="skill-status">
                  {isCompleted ? '✅ Completed' : '❌ Incomplete'}
                </span>
              </div>
              <button
                onClick={() => handleSkillClick(skill)}
                className="view-details-button"
              >
                View Details
              </button>
            </li>
          );
        })}
      </ul>
      <button onClick={() => navigate(-1)} className="back-button">
        Go Back
      </button>
    </div>
  );
};

export default SkillsList;
