// Polyfill for AJV issues
// This adds a safety wrapper around browser-side require
if (typeof window !== 'undefined') {
  // Store the original require if it exists
  const originalRequire = window.require;
  
  if (originalRequire) {
    // Replace it with our safe version
    window.require = function(modulePath) {
      try {
        return originalRequire(modulePath);
      } catch (e) {
        console.warn('[AJV Polyfill] Failed to require ' + modulePath);
        // Return a simple object with the missing function
        return {
          _: function() { 
            return { toString: () => 'new RegExp' }; 
          }
        };
      }
    };
  }
}
