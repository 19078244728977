import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './PaymentSuccess.css';

const PaymentSuccess = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const searchParams = new URLSearchParams(location.search);
  const sessionId = searchParams.get('session_id');

  const [loading, setLoading] = useState(true);
  const [sessionData, setSessionData] = useState(null);

  // Replace with your server’s URL
  const SERVER_URL = "https://stripe-checkout-server-production.up.railway.app";

  useEffect(() => {
    if (!sessionId) {
      setLoading(false);
      return;
    }
    const fetchSession = async () => {
      try {
        const res = await fetch(`${SERVER_URL}/checkout-session?sessionId=${sessionId}`);
        if (!res.ok) {
          console.error("Error fetching session:", res.statusText);
          setLoading(false);
          return;
        }
        const data = await res.json();
        setSessionData(data);
        setLoading(false);
      } catch (err) {
        console.error("Failed to fetch session:", err);
        setLoading(false);
      }
    };

    fetchSession();
  }, [sessionId]);

  if (loading) {
    return (
      <div className="payment-success-container">
        <h1>Payment Successful!</h1>
        <p>Loading details...</p>
      </div>
    );
  }

  if (!sessionId) {
    return (
      <div className="payment-success-container">
        <h1>Payment Successful!</h1>
        <p>No session ID found, but thanks for subscribing!</p>
        <a href="/">Go to Home</a>
      </div>
    );
  }

  // Use sessionData.metadata.planType if available; default to "basic"
  const planType = sessionData?.metadata?.planType || "basic";

  return (
    <div className="payment-success-container">
      <h1>Payment Successful!</h1>
      <p>Thank you for subscribing to our standard plan!</p>
      <p>
        Our standard plan guides your class through an intentional progression of 30 focus areas,
        building essential mindfulness skills every step of the way.
      </p>
      <p>
        Would you like to create a custom plan tailored to your class’s unique needs? You can always
        adjust later in your class preferences.
      </p>
      <div className="button-group">
        <button
          className="action-button"
          onClick={() => navigate('/survey')}
        >
          Create Custom Plan
        </button>
        <button
          className="action-button"
          onClick={() => navigate('/dashboard')}
        >
          Use Recommended Plan &amp; Continue to Class Preferences
        </button>
      </div>
      <p className="note">
        You can always create a custom plan later in your class settings.
      </p>
      <hr />
      <p className="session-info">Session ID: {sessionId}</p>
    </div>
  );
};

export default PaymentSuccess;
