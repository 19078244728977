// src/components/LoadingSpinner/LoadingSpinner.js
import React from 'react';
import './LoadingSpinner.css';

const LoadingSpinner = ({ size = 'medium', message = '', fullPage = false }) => {
  const sizeClass = `spinner-${size}`;
  const containerClass = fullPage ? 'loading-spinner-container full-page' : 'loading-spinner-container';

  return (
    <div className={containerClass}>
      <div className="spinner-wrapper">
        <div className={`loading-spinner ${sizeClass}`}>
          <div className="spinner-circle"></div>
          <div className="spinner-circle-inner"></div>
        </div>
        {message && <p className="loading-message">{message}</p>}
      </div>
    </div>
  );
};

export default LoadingSpinner;