import React from 'react';

const PaymentCanceled = () => {
  return (
    <div style={{ padding: '2rem' }}>
      <h1>Payment Canceled</h1>
      <p>No worries! Maybe next time.</p>
      <a href="/">Go to Home</a>
    </div>
  );
};

export default PaymentCanceled;
