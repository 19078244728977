// src/firebase/firebase.js

// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore'; // Added import
import { getAuth } from 'firebase/auth'; // Added import
// import { getAnalytics } from 'firebase/analytics'; // Remove if not used

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBXj6At5crUqe10xK-09znqlKHtrPS1Ukw",
  authDomain: "minmindful-703d0.firebaseapp.com",
  projectId: "minmindful-703d0",
  storageBucket: "minmindful-703d0.firebasestorage.app",
  messagingSenderId: "83368132728",
  appId: "1:83368132728:web:8b5b00212e6c9041c5ed8d",
  measurementId: "G-N0LVXMNDRZ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Firestore Database
const db = getFirestore(app);

// Firebase Authentication
const auth = getAuth(app);

export { db, auth };
